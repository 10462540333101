import React from 'react';

import { withFirebase } from 'contexts/Firebase';
import Button from './Button';

const SignOutButton = ({ firebase }) => (
  <Button onClick={firebase.doSignOut}>
    Sign Out
  </Button>
);

export default withFirebase(SignOutButton);
import React from "react";
import PropTypes from "prop-types";

import { withFirebase } from 'contexts/Firebase';
import { compose } from 'recompose';

import _ from 'lodash';

// react plugin for creating charts
import ChartistGraph from "react-chartist";
// react plugin for creating vector maps
import { VectorMap } from "react-jvectormap";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Tooltip from "@material-ui/core/Tooltip";
import Icon from "@material-ui/core/Icon";

// @material-ui/icons
// import ContentCopy from "@material-ui/icons/ContentCopy";
import Store from "@material-ui/icons/Store";
// import InfoOutline from "@material-ui/icons/InfoOutline";
import Warning from "@material-ui/icons/Warning";
import DateRange from "@material-ui/icons/DateRange";
import LocalOffer from "@material-ui/icons/LocalOffer";
import Update from "@material-ui/icons/Update";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import AccessTime from "@material-ui/icons/AccessTime";
import Refresh from "@material-ui/icons/Refresh";
import Edit from "@material-ui/icons/Edit";
import Place from "@material-ui/icons/Place";
import ArtTrack from "@material-ui/icons/ArtTrack";
import Language from "@material-ui/icons/Language";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Table from "components/Table/Table.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Danger from "components/Typography/Danger.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";

import personalReportStyle from "assets/jss/material-dashboard-pro-react/views/personalReportStyle";

class PersonalReport extends React.Component {
  state = {
    value: 0
  };
  handleChange = (event, value) => {
    this.setState({ value });
  };
  handleChangeIndex = index => {
    this.setState({ value: index });
  };

  parseParams = async () => {
    const { space_id, user_id } = this.props.match.params;
    console.log('space_id = ', space_id);
    console.log('user_id = ', user_id);
    const profile = await this.props.firebase.getProfileA(space_id, user_id);
    console.log('profile = ', profile);
    this.setState({ profile });
  }


  async componentDidMount() {
    await this.parseParams();
  }

  async componentWillReceiveProps(nextProps) {
    if (!_.isEqual(this.props.location, nextProps.location)) {
      await this.parseParams();
    }
  }

  render() {
    const { classes, match } = this.props;
    const { profile } = this.state;
    if (!profile) {
      return <div></div>;
    }
    const { photo_urls, hr_analysis_point, hr_analysis, last_visited_at, first_name, last_name } = profile;

    console.log('match.params.id = ', match.params)

    return profile ? (
      <div>
        <h1>{`${last_name} ${first_name}'s Personal HR Report`}</h1>
        <div className={classes.photo}>
          <img src={photo_urls['375px']} className={classes.avatarImg} alt={first_name} />
        </div>
        <div>
          <h3>{`${last_name} ${first_name}`}</h3>
        </div>
        <div>
          <h6>{`last login at ${last_visited_at.toDate()}`}</h6>
        </div>
        <div>
          <GridContainer>
            <GridItem xs={12} sm={6} md={6} lg={3}>
              <Card>
                <CardHeader color="warning" stats icon>
                  <CardIcon color="warning">
                    <Icon>done</Icon>
                  </CardIcon>
                  <p className={classes.cardCategory}>성실지수</p>
                  <h3 className={classes.cardTitle}>
                    {hr_analysis_point ? hr_analysis_point.sincere : null}
                  </h3>
                </CardHeader>
                <CardFooter stats>
                  <div className={classes.stats}>
                    성실지수 요소: 감사보내기, 감사받기, 감사미션수행, 온도나눔
                  </div>
                </CardFooter>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={6} md={6} lg={3}>
              <Card>
                <CardHeader color="success" stats icon>
                  <CardIcon color="success">
                    <Icon>thumb_up</Icon>
                  </CardIcon>
                  <p className={classes.cardCategory}>긍정지수</p>
                  <h3 className={classes.cardTitle}>
                    {hr_analysis_point ? hr_analysis_point.positive : null}
                  </h3>
                </CardHeader>
                <CardFooter stats>
                  <div className={classes.stats}>
                    긍정지수 요소: 감사보내기, 감사받기, 감사미션수행, 온도나눔
                  </div>
                </CardFooter>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={6} md={6} lg={3}>
              <Card>
                <CardHeader color="danger" stats icon>
                  <CardIcon color="danger">
                    <Icon>favorite_outline</Icon>
                  </CardIcon>
                  <p className={classes.cardCategory}>사랑지수</p>
                  <h3 className={classes.cardTitle}>
                    {hr_analysis_point ? hr_analysis_point.love : null}
                  </h3>
                </CardHeader>
                <CardFooter stats>
                  <div className={classes.stats}>
                    사랑지수 요소: 감사보내기, 온도나눔
                  </div>
                </CardFooter>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={6} md={6} lg={3}>
              <Card>
                <CardHeader color="info" stats icon>
                  <CardIcon color="info">
                    <Icon>insert_emoticon</Icon>
                  </CardIcon>
                  <p className={classes.cardCategory}>감사지수</p>
                  <h3 className={classes.cardTitle}>
                    {hr_analysis_point ? hr_analysis_point.gratitude : null}
                  </h3>
                </CardHeader>
                <CardFooter stats>
                  <div className={classes.stats}>
                    감사지수 요소: 감사보내기, 감사미션수행
                  </div>
                </CardFooter>
              </Card>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem>
              <h4>감사보내기 횟수: {hr_analysis ? hr_analysis.sent | 0 : 0}</h4>
              <h4>감사받기 횟수: {hr_analysis ? hr_analysis.received | 0 : 0}</h4>
              <h4>감사미션수행 횟수: {hr_analysis ? hr_analysis.mission | 0 : 0}</h4>
              <h4>온도나눔 횟수: {hr_analysis ? hr_analysis.inspire | 0 : 0}</h4>
            </GridItem>
          </GridContainer>
        </div>
      </div>
    ) : null;
  }
}

PersonalReport.propTypes = {
  classes: PropTypes.object.isRequired
};

export default compose(
  withFirebase,
  withStyles(personalReportStyle),
  // withAuthentication,
)(PersonalReport);
import React from 'react';

import AuthUserContext from './context';
import { withFirebase } from 'contexts/Firebase';

const withAuthentication = Component => {
  class WithAuthentication extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        authUser: JSON.parse(localStorage.getItem('authUser')),
        userSpaces: JSON.parse(localStorage.getItem('currentUserSpaces')),
        currentUserSpaces: JSON.parse(localStorage.getItem('currentUserSpaces')),
      };

      console.log('this.state = ', this.state);
    }

    componentDidMount() {
      this.listener = this.props.firebase.auth.onAuthStateChanged(
        async authUser => {
          if (authUser) {
            const currentUserSpaces = await this.props.firebase.getUserSpaces(authUser.uid);
            console.log('all spaces = ', currentUserSpaces);
            this.setState({ authUser, currentUserSpaces });

            localStorage.setItem('authUser', JSON.stringify(authUser));
            localStorage.setItem('currentUserSpaces', JSON.stringify(currentUserSpaces));
          } else {
            this.setState({ authUser: null });
          }
        },
      );
    }

    componentWillUnmount() {
      this.listener();
    }

    render() {
      const { authUser, currentUserSpaces } = this.state;

      return (
        <AuthUserContext.Provider value={authUser}>
          <Component {...this.props} authUser={authUser} currentUserSpaces={currentUserSpaces} />
        </AuthUserContext.Provider>
      );
    }
  }

  return withFirebase(WithAuthentication);
};

export default withAuthentication;
import React from "react";
import PropTypes from "prop-types";

import { withFirebase } from 'contexts/Firebase';
import { compose } from 'recompose';

import _ from 'lodash';

import { NavLink, Link } from "react-router-dom";

// react plugin for creating charts
import ChartistGraph from "react-chartist";
// react plugin for creating vector maps
import { VectorMap } from "react-jvectormap";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Tooltip from "@material-ui/core/Tooltip";
import Icon from "@material-ui/core/Icon";

// @material-ui/icons
// import ContentCopy from "@material-ui/icons/ContentCopy";
import Store from "@material-ui/icons/Store";
// import InfoOutline from "@material-ui/icons/InfoOutline";
import Warning from "@material-ui/icons/Warning";
import DateRange from "@material-ui/icons/DateRange";
import LocalOffer from "@material-ui/icons/LocalOffer";
import Update from "@material-ui/icons/Update";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import AccessTime from "@material-ui/icons/AccessTime";
import Refresh from "@material-ui/icons/Refresh";
import Edit from "@material-ui/icons/Edit";
import Place from "@material-ui/icons/Place";
import ArtTrack from "@material-ui/icons/ArtTrack";
import Language from "@material-ui/icons/Language";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Table from "components/Table/Table.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Danger from "components/Typography/Danger.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";

import {
  dailySalesChart,
  emailsSubscriptionChart,
  completedTasksChart
} from "variables/charts";

import dashboardStyle from "assets/jss/material-dashboard-pro-react/views/dashboardStyle";

class Dashboard extends React.Component {
  state = {
    value: 0
  };
  handleChange = (event, value) => {
    this.setState({ value });
  };
  handleChangeIndex = index => {
    this.setState({ value: index });
  };

  async componentDidMount() {
    console.log('location = ', this.props.location);
    const { pathname = '' } = this.props.location;
    const space_id = _.last(pathname.split('/'));
    console.log('space_id = ', space_id);
    if (space_id) {
      const space = await this.props.firebase.getSpace(space_id);
      console.log('****** space : ', space);
      let groups = await this.props.firebase.getGroups(space_id);
      console.log('****** groups: ', groups);
      const group_stats = await Promise.all(groups.map(group => this.props.firebase.getHRAStatByGroup(space_id, group.group_id)));

      groups = groups.map((group, index) => ({ ...group, stats: group_stats[index] }))
      console.log('****** groups: ', groups);
      this.setState({ groups, space });
    }
  }

  async componentWillReceiveProps(nextProps) {
    if (!_.isEqual(this.props.location.space, nextProps.location.space)) {
      console.log('nextProps.location: ', nextProps.location);
      const { space_id } = nextProps.location.space;

      let groups = await this.props.firebase.getGroups(space_id);
      const group_stats = await Promise.all(groups.map(group => this.props.firebase.getHRAStatByGroup(space_id, group.group_id)));

      groups = groups.map((group, index) => ({ ...group, stats: group_stats[index] }))
      console.log('****** groups: ', groups);
      this.setState({ groups, space: nextProps.location.space });
    }
  }

  render() {
    const { classes } = this.props;
    const { space } = this.state;
    const groups = _.sortBy(this.state.groups, ['title']);

    return space && groups ? (
      <div>
        <h1>{`${space.title} (${space.space_id})`}</h1>
        {
          groups.map(group => {
            const { stats, title, group_id } = group;
            return (
              <div key={group.group_id}>
                <h2>{`${title} group (${group_id})`}</h2>
                <GridContainer>
                  <GridItem xs={12} sm={6} md={6} lg={3}>
                    <Card>
                      <CardHeader color="warning" stats icon>
                        <CardIcon color="warning">
                          <Icon>done</Icon>
                        </CardIcon>
                        <p className={classes.cardCategory}>성실지수</p>
                        <h3 className={classes.cardTitle}>
                          {stats.hr_analysis_point_sum ? stats.hr_analysis_point_sum.sincere : null}
                        </h3>
                      </CardHeader>
                      <CardBody>
                        <Table
                          tableData={
                            stats.hr_analysis_point_rank
                              ? stats.hr_analysis_point_rank.sincere.map((profile, index) => {
                                const display_name = `${profile.last_name}${profile.first_name}`;
                                console.log('display_name = ', display_name);
                                return [
                                  <div key={index} className={classes.photo}>
                                    <img src={profile.photo_urls['128px']} className={classes.avatarImg} alt={display_name} />
                                  </div>,
                                  <div>
                                    <Link
                                      to={`/admin/spaces/${space.space_id}/profiles/${profile.user_id}/personalReport`}
                                    >
                                      {display_name}
                                    </Link>
                                  </div>,
                                  profile.hr_analysis_point ? profile.hr_analysis_point.sincere : 0,
                                ]
                              })
                              : null
                          }
                        />
                      </CardBody>
                      <CardFooter stats>
                        <div className={classes.stats}>
                          성실지수 요소: 감사보내기, 감사받기, 감사미션수행, 온도나눔
                  </div>
                      </CardFooter>
                    </Card>
                  </GridItem>
                  <GridItem xs={12} sm={6} md={6} lg={3}>
                    <Card>
                      <CardHeader color="success" stats icon>
                        <CardIcon color="success">
                          <Icon>thumb_up</Icon>
                        </CardIcon>
                        <p className={classes.cardCategory}>긍정지수</p>
                        <h3 className={classes.cardTitle}>
                          {stats.hr_analysis_point_sum ? stats.hr_analysis_point_sum.positive : null}
                        </h3>
                      </CardHeader>
                      <CardBody>
                        <Table
                          tableData={
                            stats.hr_analysis_point_rank
                              ? stats.hr_analysis_point_rank.positive.map((profile, index) => {
                                const display_name = `${profile.last_name}${profile.first_name}`;
                                console.log('display_name = ', display_name);
                                return [
                                  <div key={index} className={classes.photo}>
                                    <img src={profile.photo_urls['128px']} className={classes.avatarImg} alt={display_name} />
                                  </div>,
                                  <Link
                                    to={`/admin/spaces/${space.space_id}/profiles/${profile.user_id}/personalReport`}
                                  >
                                    {display_name}
                                  </Link>,
                                  profile.hr_analysis_point ? profile.hr_analysis_point.positive : 0,
                                ]
                              })
                              : null

                          }
                        />
                      </CardBody>
                      <CardFooter stats>
                        <div className={classes.stats}>
                          긍정지수 요소: 감사보내기, 감사받기, 감사미션수행, 온도나눔
                  </div>
                      </CardFooter>
                    </Card>
                  </GridItem>
                  <GridItem xs={12} sm={6} md={6} lg={3}>
                    <Card>
                      <CardHeader color="danger" stats icon>
                        <CardIcon color="danger">
                          <Icon>favorite_outline</Icon>
                        </CardIcon>
                        <p className={classes.cardCategory}>사랑지수</p>
                        <h3 className={classes.cardTitle}>
                          {stats.hr_analysis_point_sum ? stats.hr_analysis_point_sum.love : null}
                        </h3>
                      </CardHeader>
                      <CardBody>
                        <Table
                          tableData={
                            stats.hr_analysis_point_rank
                              ? stats.hr_analysis_point_rank.love.map((profile, index) => {
                                const display_name = `${profile.last_name}${profile.first_name}`;
                                console.log('display_name = ', display_name);
                                return [
                                  <div key={index} className={classes.photo}>
                                    <img src={profile.photo_urls['128px']} className={classes.avatarImg} alt={display_name} />
                                  </div>,
                                  <Link
                                    to={`/admin/spaces/${space.space_id}/profiles/${profile.user_id}/personalReport`}
                                  >
                                    {display_name}
                                  </Link>,
                                  profile.hr_analysis_point ? profile.hr_analysis_point.love : 0,
                                ]
                              })
                              : null
                          }
                        />
                      </CardBody>
                      <CardFooter stats>
                        <div className={classes.stats}>
                          사랑지수 요소: 감사보내기, 온도나눔
                  </div>
                      </CardFooter>
                    </Card>
                  </GridItem>
                  <GridItem xs={12} sm={6} md={6} lg={3}>
                    <Card>
                      <CardHeader color="info" stats icon>
                        <CardIcon color="info">
                          <Icon>insert_emoticon</Icon>
                        </CardIcon>
                        <p className={classes.cardCategory}>감사지수</p>
                        <h3 className={classes.cardTitle}>
                          {stats.hr_analysis_point_sum ? stats.hr_analysis_point_sum.gratitude : null}
                        </h3>
                      </CardHeader>
                      <CardBody>
                        <Table
                          tableData={
                            stats.hr_analysis_point_rank
                              ? stats.hr_analysis_point_rank.gratitude.map((profile, index) => {
                                const display_name = `${profile.last_name}${profile.first_name}`;
                                console.log('display_name = ', display_name);
                                return [
                                  <div key={index} className={classes.photo}>
                                    <img src={profile.photo_urls['128px']} className={classes.avatarImg} alt={display_name} />
                                  </div>,
                                  <Link
                                    to={`/admin/spaces/${space.space_id}/profiles/${profile.user_id}/personalReport`}
                                  >
                                    {display_name}
                                  </Link>,
                                  profile.hr_analysis_point ? profile.hr_analysis_point.gratitude : 0,
                                ]
                              })
                              : null
                          }
                        />
                      </CardBody>
                      <CardFooter stats>
                        <div className={classes.stats}>
                          감사지수 요소: 감사보내기, 감사미션수행
                  </div>
                      </CardFooter>
                    </Card>
                  </GridItem>
                </GridContainer>
              </div>
            )
          })
        }
      </div>
    ) : null;
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired
};

export default compose(
  withFirebase,
  withStyles(dashboardStyle),
  // withAuthentication,
)(Dashboard);
import React from "react";
import PropTypes from "prop-types";
import { compose } from 'recompose';
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";

// @material-ui/icons
import Face from "@material-ui/icons/Face";
import Email from "@material-ui/icons/Email";
// import LockOutline from "@material-ui/icons/LockOutline";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardFooter from "components/Card/CardFooter.jsx";

import loginPageStyle from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.jsx";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import { whiteColor, grayColor } from "assets/jss/material-dashboard-pro-react.jsx";
import buttonStyle from "assets/jss/material-dashboard-pro-react/components/buttonStyle.jsx";

import { withFirebase } from "contexts/Firebase";
import * as ROUTES from 'constants/routes';
import SweetAlert from "react-bootstrap-sweetalert";

class LoginPage extends React.Component {
  constructor(props) {
    super(props);
    // we use this to make the card to appear after the page has been rendered
    this.state = {
      cardAnimaton: "cardHidden",
      alert: null,
      show: false,
    };
  }
  componentDidMount() {
    // we add a hidden class to the card and after 700 ms we delete it and the transition appears
    this.timeOutFunction = setTimeout(
      function () {
        this.setState({ cardAnimaton: "" });
      }.bind(this),
      700
    );
  }
  componentWillUnmount() {
    clearTimeout(this.timeOutFunction);
    this.timeOutFunction = null;
  }

  basicAlert = (message) => {
    this.setState({
      alert: (
        <SweetAlert
          style={{
            marginBottom: "3px",
            color: grayColor[2],
            fontSize: "18px",

            display: "block",
            marginTop: "-100px",
          }}
          title={message}
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
        />
      )
    });
  }

  hideAlert = () => {
    this.setState({
      alert: null
    });
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.container}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={6} md={4}>
            <form>
              <Card login className={classes[this.state.cardAnimaton]}>
                <CardHeader
                  className={`${classes.cardHeader} ${classes.textCenter}`}
                  color="rose"
                >
                  <h4 className={{ ...classes.cardTitle }}>Just Thank You Log in</h4>
                  <div className={classes.socialLine}>
                    {/* {[
                      "fab fa-facebook-square",
                      "fab fa-twitter",
                      "fab fa-google-plus"
                    ].map((prop, key) => {
                      return (
                        <Button
                          color="transparent"
                          justIcon
                          key={key}
                          className={classes.customButtonClass}
                        >
                          <i className={prop} />
                        </Button>
                      );
                    })} */}
                  </div>
                </CardHeader>
                <CardBody>
                  {/* <CustomInput
                    labelText="First Name.."
                    id="firstname"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Face className={classes.inputAdornmentIcon} />
                        </InputAdornment>
                      )
                    }}
                  /> */}
                  <CustomInput
                    labelText="이메일"
                    id="email"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      onChange: (e) => this.setState({ email: e.target.value }),
                      endAdornment: (
                        <InputAdornment position="end">
                          <Email className={classes.inputAdornmentIcon} />
                        </InputAdornment>
                      )
                    }}
                  />
                  <CustomInput
                    labelText="패스워드"
                    id="password"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      onChange: e => this.setState({ password: e.target.value }),
                      type: 'password',
                      endAdornment: (
                        <InputAdornment position="end">
                          <Icon className={classes.inputAdornmentIcon}>
                            lock_outline
                          </Icon>
                        </InputAdornment>
                      )
                    }}
                  />
                </CardBody>
                <CardFooter className={classes.justifyContentCenter}>
                  <Button onClick={() => {
                    const { email, password } = this.state;
                    console.log(`email = ${email}, pw = ${password}`);
                    this.props.firebase.doSignInWithEmailAndPassword(email, password)
                      .then(user => {
                        console.log(`user = ${JSON.stringify(user)}`)
                        this.props.history.push(ROUTES.ADMIN);
                      })
                      .catch(e => {
                        console.error('Fail to doSignInWithEmailAndPassword: ', e)
                        this.basicAlert(e.message);
                      })
                  }} color="rose" simple size="lg" block>
                    로그인
                  </Button>
                </CardFooter>
              </Card>
            </form>
          </GridItem>
        </GridContainer>
        {this.state.alert}
      </div >
    );
  }
}

LoginPage.propTypes = {
  classes: PropTypes.object.isRequired
};

export default compose(
  withFirebase,
  // withStyles(
  //   (theme) => ({
  //     ...loginPageStyle(theme),
  //     ...buttonStyle(theme),
  //   }),
  //   { withTheme: true }
  // ),
  withStyles(
    (theme) => ({
      ...loginPageStyle(theme),
      ...sweetAlertStyle,
    }),
    { withTheme: true },
  ),
)(LoginPage);

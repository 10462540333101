import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import { SPACE_STATUS, SPACE_TYPES } from 'constants/space';
import _ from 'lodash';

const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_ID,
};

class Firebase {
  constructor() {
    app.initializeApp(config);

    this.auth = app.auth();
    this.db = app.firestore();
  }

  // *** Auth API ***
  doCreateUserWithEmailAndPassword = (email, password) =>
    this.auth.createUserWithEmailAndPassword(email, password);

  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password);

  doSignOut = () => this.auth.signOut();

  doPasswordReset = email => this.auth.sendPasswordResetEmail(email);

  doPasswordUpdate = password =>
    this.auth.currentUser.updatePassword(password);

  getCurrentUser = () => this.auth.currentUser;

  // **** DB API
  getUserSpaces = async (user_id) => await this.db.doc(`users_space/${user_id}`).get()
    .then(docSnap => docSnap.data())
    .then(userSpace => {
      console.log('userSpace = ', userSpace)
      this.currentSpaceId = userSpace.space_id;
      if (userSpace.spaces) {
        const space_ids = Object.keys(userSpace.spaces) || [];
        return Promise.all(space_ids.map(space_id => this.db.doc(`spaces/${space_id}`).get()))
          .then(docSnaps =>
            docSnaps
              .map(docSnap => docSnap.data())
              .filter(space => space) // not null
              .filter(space => space.status === SPACE_STATUS.NORMAL && space.type === SPACE_TYPES.B2B)
          )
      } else {
        return null;
      }
    });

  getSpace = async (space_id) => await this.db.doc(`spaces/${space_id}`)
    .get()
    .then(snap => snap.data());

  getGroups = async (space_id) => await this.db.collection(`spaces/${space_id}/groups`)
    .get()
    .then(colSnap => colSnap.docs.map(doc => doc.data()));

  getUser = async (space_id, user_id) => await this.db.doc(`spaces/${space_id}/users/${user_id}`)
    .get()
    .then(snap => snap.data());

  getProfile = (space_id, user_id) => this.db.doc(`spaces/${space_id}/profiles/${user_id}`)
    .get()
    .then(snap => snap.data());

  getProfileA = async (space_id, user_id) => await this.db.doc(`spaces/${space_id}/profiles/${user_id}`)
    .get()
    .then(snap => snap.data());

  getHRAStatByGroup = async (space_id, group_id) =>
    await this.db.collection(`spaces/${space_id}/users`)
      .where(`groups.${group_id}`, '>', 0)
      .get()
      .then(colSnap => colSnap.docs.map(doc => doc.id))
      .then(user_ids => Promise.all(user_ids.map(user_id => this.getProfile(space_id, user_id))))
      .then(profiles => profiles.filter(profile => profile.hr_analysis && profile.hr_analysis_point))
      .then(profiles => calcHRAStat(profiles))
      .catch(e => console.error('failed to get hr analysis stat by group', e))

  getHRAStat = async (space_id) =>
    await this.db.collection(`spaces/${space_id}/profiles`)
      .get()
      .then(colSnap => colSnap.docs.map(doc => doc.data()))
      .then(profiles => profiles.filter(profile => profile.hr_analysis && profile.hr_analysis_point))
      .then(profiles => calcHRAStat(profiles))
      .catch(e => console.error('failed to get hr analysis stat', e))
}


const calcHRAStat = (profiles) => {
  console.log('num of profiles = ', profiles.length);
  const hr_analysis_sum = {
    inspire: _.sumBy(profiles, 'hr_analysis.inspire'),
    mission: _.sumBy(profiles, 'hr_analysis.mission'),
    received: _.sumBy(profiles, 'hr_analysis.received'),
    sent: _.sumBy(profiles, 'hr_analysis.sent'),
  }
  const hr_analysis_point_sum = {
    gratitude: _.ceil(_.sumBy(profiles, 'hr_analysis_point.gratitude'), 2),
    love: _.ceil(_.sumBy(profiles, 'hr_analysis_point.love'), 2),
    positive: _.ceil(_.sumBy(profiles, 'hr_analysis_point.positive'), 2),
    sincere: _.ceil(_.sumBy(profiles, 'hr_analysis_point.sincere'), 2),
  }
  const hr_analysis_mean = {
    inspire: _.ceil(_.meanBy(profiles, 'hr_analysis.inspire'), 2),
    mission: _.ceil(_.meanBy(profiles, 'hr_analysis.mission'), 2),
    received: _.ceil(_.meanBy(profiles, 'hr_analysis.received'), 2),
    sent: _.ceil(_.meanBy(profiles, 'hr_analysis.sent'), 2),
  }
  const hr_analysis_point_mean = {
    gratitude: _.ceil(_.meanBy(profiles, 'hr_analysis_point.gratitude'), 2),
    love: _.ceil(_.meanBy(profiles, 'hr_analysis_point.love'), 2),
    positive: _.ceil(_.meanBy(profiles, 'hr_analysis_point.positive'), 2),
    sincere: _.ceil(_.meanBy(profiles, 'hr_analysis_point.sincere'), 2),
  }
  const hr_analysis_point_rank = {
    gratitude: _(profiles).orderBy(['hr_analysis_point.gratitude'], ['desc']).take(50).value(),
    love: _(profiles).orderBy(['hr_analysis_point.love'], ['desc']).take(50).value(),
    positive: _(profiles).orderBy(['hr_analysis_point.positive'], ['desc']).take(50).value(),
    sincere: _(profiles).orderBy(['hr_analysis_point.sincere'], ['desc']).take(50).value(),
  }
  return { hr_analysis_sum, hr_analysis_point_sum, hr_analysis_mean, hr_analysis_point_mean, hr_analysis_point_rank };
}





export default Firebase;

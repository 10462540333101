export const SPACE_TYPES = {
  B2C: 1,
  B2B: 2
}

export const SPACE_STATUS = {
  NORMAL: 0,
  EXPIRED: 1
}

export const USER_SPACE_STATUS = {
  NORMAL: 0,
  EXPIRED: 1,
  LEAVE: 2
}
